import React, {useEffect} from 'react';
import styles from "./styles.module.css";
import {servicePage7} from "../content/Text";

const Sp7 = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className={styles.wrapper}>
            <div className={styles.block}>
                <h1 className={styles.mainTitle}>{servicePage7.firstBlockTitle}</h1>
                <p className={styles.paragraph}>{servicePage7.firstBlock1Paragraph}</p>
            </div>
            <div className={styles.block}>
                <h1 className={styles.mainTitle}>{servicePage7.secondBlockTitle}</h1>
                <p className={styles.paragraph}>{servicePage7.secondBlock1Paragraph}</p>
                <p className={styles.paragraph}>{servicePage7.secondBlock2Paragraph}</p>
                <p className={styles.paragraph}>{servicePage7.secondBlock3Paragraph}</p>
                <p className={styles.paragraph}>{servicePage7.secondBlock4Paragraph}</p>
                <p className={styles.paragraph}>{servicePage7.secondBlock5Paragraph}</p>
                <p className={styles.paragraph}>{servicePage7.secondBlock6Paragraph}</p>
                <p className={styles.paragraph}>{servicePage7.secondBlock7Paragraph}</p>
            </div>
            <div className={styles.block}>
                <h1 className={styles.mainTitle}>{servicePage7.thirdBlockTitle}</h1>
                <p className={styles.paragraph}>{servicePage7.thirdBlock1Paragraph}</p>
                <p className={styles.paragraph}>{servicePage7.thirdBlock2Paragraph}</p>
                <p className={styles.paragraph}>{servicePage7.thirdBlock3Paragraph}</p>
            </div>
        </div>
    );
};

export default Sp7;
import React from 'react';
import styles from './styles.module.css'
import {aboutUs} from "../content/Text";


const AboutPage = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.block} style={{height: '60vh'}}>
                <h1 className={styles.mainTitle}>{aboutUs.firstBlockTitle}</h1>
                <p className={styles.paragraph}>{aboutUs.firstBlock1Paragraph}</p>
            </div>
        </div>
    );
};

export default AboutPage;
import React from 'react';
import styles from './styles.module.css'

type Props = {
    onClick: () => void;
}

const Fade = ({onClick}: Props) => {
    return (
        <div className={styles.fade} onClick={onClick} />
    );
};

export default Fade;
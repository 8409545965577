import React, {useEffect} from 'react';
import styles from "./styles.module.css";
import {servicePage3} from "../content/Text";

const Sp3 = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className={styles.wrapper}>
            <div className={styles.block}>
                <h1 className={styles.mainTitle}>{servicePage3.firstBlockTitle}</h1>
                <p className={styles.paragraph}>{servicePage3.firstBlock1Paragraph}</p>
                <p className={styles.paragraph}>{servicePage3.firstBlock2Paragraph}</p>
            </div>
            <div className={styles.block}>
                <h2 className={styles.mainTitle}>{servicePage3.secondBlockTitle}</h2>
                <p className={styles.paragraph}>{servicePage3.secondBlock1Paragraph}</p>
                <p className={styles.paragraph}>{servicePage3.secondBlock2Paragraph}</p>
                <p className={styles.paragraph}>{servicePage3.secondBlock3Paragraph}</p>
            </div>
            <div className={styles.block}>
                <h3 className={styles.mainTitle}>{servicePage3.thirdBlockTitle}</h3>
                <ul className={styles.list}>{servicePage3.thirdBlock1List.map(((listItem, index) => <li
                    key={listItem}>{listItem}</li>))}</ul>
                <p className={styles.paragraph}>{servicePage3.thirdBlock1Paragraph}</p>
            </div>
            <div className={styles.block}>
                <h3 className={styles.mainTitle}>{servicePage3.fourthBlockTitle}</h3>
                <ul className={styles.list}>{servicePage3.fourthBlock1List.map(((listItem, index) => <li
                    key={listItem}>{listItem}</li>))}</ul>
                <p className={styles.paragraph}>{servicePage3.thirdBlock1Paragraph}</p>
            </div>
            <div className={styles.block}>
                <h2 className={styles.mainTitle}>{servicePage3.fifthBlockTitle}</h2>
                <p className={styles.paragraph}>{servicePage3.fifthBlock1Paragraph}</p>
                <p className={styles.paragraph}>{servicePage3.fifthBlock2Paragraph}</p>
                <p className={styles.paragraph}>{servicePage3.fifthBlock3Paragraph}</p>
                <p className={styles.paragraph}>{servicePage3.fifthBlock4Paragraph}</p>
                <p className={styles.paragraph}>{servicePage3.fifthBlock5Paragraph}</p>
            </div>
        </div>
    );
};

export default Sp3;
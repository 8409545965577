import React, {useEffect} from 'react';
import styles from "./styles.module.css";
import {servicePage6} from "../content/Text";

const Sp6 = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className={styles.wrapper}>
            <div className={styles.block}>
                <h1 className={styles.mainTitle}>{servicePage6.firstBlockTitle}</h1>
                <p className={styles.paragraph}>{servicePage6.firstBlock1Paragraph}</p>
                <h4 className={styles.paragraph}
                    style={{width: '100%', justifySelf: 'start'}}>{servicePage6.firstBlock2Title}</h4>
            </div>
        </div>
    );
};

export default Sp6;
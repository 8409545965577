import React, {useEffect} from 'react';
import styles from "./styles.module.css";
import {servicePage9} from "../content/Text";

const Sp9 = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    return (
        <div className={styles.wrapper}>
            <div className={styles.block}>
                <h1 className={styles.mainTitle}>{servicePage9.firstBlockTitle}</h1>
                <p className={styles.paragraph}>{servicePage9.firstBlock1Paragraph}</p>
                <p className={styles.paragraph}>{servicePage9.firstBlock2Paragraph}</p>
            </div>
            <div className={styles.block}>
                <h2 className={styles.mainTitle}>{servicePage9.secondBlockTitle}</h2>
                <p className={styles.paragraph}>{servicePage9.secondBlock1Paragraph}</p>
                <p className={styles.paragraph}>{servicePage9.secondBlock2Paragraph}</p>
                <p className={styles.paragraph}>{servicePage9.secondBlock3Paragraph}</p>
                <p className={styles.paragraph}>{servicePage9.secondBlock4Paragraph}</p>
                <p className={styles.paragraph}>{servicePage9.secondBlock5Paragraph}</p>
                <p className={styles.paragraph}>{servicePage9.secondBlock6Paragraph}</p>
            </div>
        </div>
    );
};

export default Sp9;
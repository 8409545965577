import React from 'react';
import styles from './styles.module.css'
import DropdownTab from "./AsideBarTab";
import {servicePageDropdownTabs} from "../../content/servicePageDropdownTabs";

type Props = {
    setActiveTab: (activeTab: number) => void;
    setDropdownOpen: (isOpen: boolean) => void;
}

const ServicePageDropdown = ({setActiveTab, setDropdownOpen}: Props) => {
    return (
        <div className={styles.dropdownWrapper} onClick={() => setDropdownOpen(false)}>
            {servicePageDropdownTabs.map((title, index) => (<DropdownTab key={index} content={title} onClick={() => setActiveTab(index)} isDriverTab={false}/>))}
        </div>
    );
};

export default ServicePageDropdown;
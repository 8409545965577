export const email = 'office@rsc.moscow'

export const mainPageText = {
    firstBlockTitle: 'Региональная сервисная компания',
    firstBlock1Paragraph: 'Мы ценим каждого нашего партнера, будь то крупная компания или небольшой предприниматель. Мы стремимся к индивидуальному подходу к каждому клиенту, помогая оптимизировать выбор материалов и объем проводимых работ, с целью экономии вашего времени и денег. Мы предлагаем различные способы оплаты и готовы работать как по предоплате, так и по факту завершения работ, для вашего удобства.',
    firstBlock2Paragraph: 'Наш автопарк и мобильная аварийно-техническая служба постоянно растут, а наши специалисты постоянно повышают свою квалификацию. Главное требование для всех наших сотрудников – это высокое качество работы и оперативность.',
    secondBlockTitle: '7 причин, почему стоит выбрать нас:',
    secondBlockParagraphList: [
        '1. Надежность – мы работаем только с проверенными, надежными материалами и выполняем работы на высоком уровне',
        '2. Безопасность и контроль – мы контролируем все процессы, чтобы быть уверенными в качестве наших услуг',
        '3. Технологичность – мы используем современные методы и технологии для выполнения работ',
        '4. Профессионализм – наши специалисты отличаются высоким профессионализмом и объективным подходом к работе',
        '5. Ответственность – мы морально и юридически отвечаем за качество наших услуг перед нашими клиентами',
        '6. Гибкость – мы готовы предложить особые условия для крупных партнеров',
        '7. Удобство – мы стараемся учитывать удобство и требования наших клиентов, предлагая наилучшие сроки и время работы',
    ],
    thirdBlockTitle: 'Наши услуги',
    thirdBlock1Paragraph: 'Компания "РСК" уже с 2015 года успешно выполняет широкий спектр работ в сфере управления и эксплуатации недвижимости. Наш опыт, квалификация специалистов и обширные ресурсы позволяют нам предложить клиентам высококачественные услуги, отвечающие даже самым высоким требованиям.',
    thirdBlock2Paragraph: 'Мы готовы взять на себя все хлопоты по управлению недвижимостью, чтобы вы могли сосредоточиться на своем основном бизнесе, сэкономив при этом время и ресурсы. Наша компания гарантирует эффективное решение всех задач и превосходный сервис для каждого клиента.',
}

export const servicePage1 = {
    firstBlockTitle: 'Клининговые услуги',
    firstBlock1Paragraph: 'Клининг (Cleaning) или Профессиональная уборка - это мероприятия, включающие комплексный уход за помещениями, зданиями, прилегающей территорией, осуществляемые квалифицированными специалистами с применением уборочных технологий, оборудования, инвентаря, моющих и чистящих средств.',
    firstBlock2Paragraph: 'Сегодня, повседневная жизнедеятельности любой компании неотъемлемо связана и зависит от качества и объема услуг профессиональной уборки (Клининга).',
    firstBlock3Paragraph: 'Бизнес-центры, торгово-развлекательные комплексы, складские (логистические) комплексы, магазины и торговые сети, выставочные и жилые комплексы, промышленные предприятия и предприятия общественного питания, культурно-просветительные учреждения – вот неполный перечень коммерческих и бюджетных объектов – потребителей услуг.',
    firstBlock4Paragraph: 'Профессиональная уборка помещений гарантирует не только чистоту помещений и прилегающей территории, но и создает здоровую, комфортную атмосферу уюта.',
    firstBlock5Paragraph: 'Преимущество наших услуг – наведение порядка и чистоты, с исключением вмешательства в ритм работы объекта, либо Вашей жизни. Мы моем, чистим, убираем и, при этом, остаемся незаметными',
    firstBlock6Paragraph: 'Наш персонал имеет необходимые знания и опыт, благодаря чему мы можем гарантировать отличный результат комплексной уборки помещений и прилегающей территории, независимо от объема работы.',
    firstBlock7Paragraph: 'Мы избавим Вас от рисков и издержек, возникающих при работе безответственного и непрофессионального специалиста по уборке.',
    firstBlock8Paragraph: 'Мы избавим Вас от необходимости в поиске уборщиц и дворников, контроля за ними, обеспечении их оборудованием, инвентарем и материалами, вникании в технические параметры оборудования.',
}

export const servicePage2 = {
    firstBlockTitle: 'Генеральная уборка помещений',
    firstBlock1Paragraph: 'Генеральная уборка – это комплексная услуга, направленная на удаление загрязнений из труднодоступных мест, требующая применения специальной техники и чистящих средств. Выполняется уборка помещений в офисе, жилых или торговых зданиях с периодичностью, согласованной с Заказчиком.',
    secondBlockTitle: 'Виды работ',
    secondBlock1Paragraph: 'При проведении генеральной уборки помещений специалистами ООО «РСК» производятся следующие виды работ',
    secondBlockParagraphList: [
        '1. Чистка напольных покрытий. Генеральная уборка включает химическую, паровую, пенную чистку ковровых, линолеумных, керамических напольных покрытий, а также полировку ламината, паркета. Нанесение защитного водо- и грязеотталкивающего слоя позволяет увеличить срок службы полов.',
        '2. Чистка обивки мебели. Уборка включает вакуумную, экстракторную обработку, влажную чистку, постобработку антистатическим и защитным составом текстильной, кожаной обивки диванов, кресел, стульев. Профессиональная чистка обивки позволяет не только сохранить эстетичный вид мебели, но и защитить помещения от распространения вредных микроорганизмов и болезнетворных бактерий.',
        '3. Мытье дверных блоков. Уборка включает химическую чистку деревянных, пластиковых дверей, а также влажную чистку проемов.',
        '4. Чистка вентиляционных решеток. Уборка включает влажную химическую, паровую мойку решеток и диффузоров, удаление загрязнений пылесосом, чистку вентиляторов, дезинфекцию. Данные работы способствуют улучшению циркуляции воздуха через систему вентиляции и поддержанию оптимального температурно-влажностного режима в помещении.',
        '5. Обработка мебели. Генеральная уборка включает обработку деревянных поверхностей полиролью. Использование специальных средств позволяет защитить покрытие мебели, а также придает поверхности блеск и гладкость.',
        '6. Мытье стеклянных поверхностей. Генеральная уборка включает предварительную обработку скребком (при сильном загрязнении), влажную чистку зеркал, стекол окон и дверей, а также нанесение защитного грязеотталкивающего состава на их поверхности. Мыть окна на высоте более 2 м позволяет метод промышленного альпинизма.',
    ],
    thirdBlockTitle: 'Средства генеральной уборки',
    thirdBlock1Paragraph: 'Оборудование. Во время ежедневной уборки помещений используются пылесосы, циклоны, грязеводососы, однодисковые машины для чистки полов с разными покрытиями, поломоечная, ковромоечная и подметальная спецтехника, тележки, швабры, скребки. Уборка производится с применением оборудования ведущих мировых производителей EASTMOP, COLUMBUS, TASKI, UNGER.',
    thirdBlock2Paragraph: 'Моющие вещества. Для проведения генеральной уборки используются чистящие концентраты, шампуни, средства для сухой, пенной чистки. Клининговая компания применяет средства ведущих мировых и отечественных торговых марок ECOLAB, АМС-Медиа, TASKI, DR. SCHNELL CHEMIE, KIEHL.',
    thirdBlock3Paragraph: 'Наведение порядка может отнять много Вашего времени. Доверьте генеральную уборку профессионалам! При заказе услуг наша компания дает гарантию качественного выполнения работ в срок.',
    thirdBlock4Paragraph: 'Цену наших услуг можно удобно рассчитать для Вас по Вашему Техническому заданию, планировкам помещений и территории.',
}

export const servicePage3 = {
    firstBlockTitle: 'Ежедневная уборка внутренних и прилегающих территорий',
    firstBlock1Paragraph: 'Общая территория организации включает помимо основного помещения асфальтированные тротуары, подъездные дороги, автомобильные стоянки для сотрудников и посетителей, скамейки, газоны, клумбы, зеленые насаждения. Все это требует должного внимания и ухода, т.к. красивая, облагороженная территория является одной из составляющих положительного имиджа предприятия.',
    firstBlock2Paragraph: 'ООО «РСК» предлагает свои услуги по круглогодичной комплексной уборке территории и помещений. Работы осуществляются компетентными специалистами с применением профессионального оборудования, уборочной техники, инвентаря.',
    secondBlockTitle: 'Виды работ вне помещений',
    secondBlock1Paragraph: 'Работы, проводимые на внутренних и прилегающих территориях, делятся на три вида.',
    secondBlock2Paragraph: 'Уборка, не зависящая от времени года. К данному виду работ относится уборка территорий от мусора с применением ручного инструмента и специальной уборочной техники. В любое время года выполняются сбор и вывоз мусора, очистка и промывка урн, убирается территория, где размещаются контейнеры для бытовых отходов.',
    secondBlock3Paragraph: 'Уборка в летний период. В летний период важной задачей является уборка листвы и сора. Для увлажнения воздуха и снижения степени запыленности территория поливается водой. Также ведутся работы по уходу за зелеными насаждениями. На участке осуществляется полив растений, стрижка газонов, кустарников, уборка мусора с газонов.',
    thirdBlockTitle: 'Также производятся работы по:',
    thirdBlock1List: [
        '1. удалению мусора из урн и их промывка;',
        '2. уборке площадки размещения мусорных контейнеров и бункеров;',
        '3. удалению мусора из дренажных каналов;',
        '4. взаимодействию с контролирующими органами.'
    ],
    thirdBlock1Paragraph: 'Уборка в зимний период. В зимний сезон в первую очередь территория очищается от снега и наледи. В этот период осуществляются уборка и вывоз снежных масс, подметание улиц, обработка антигололедными реагентами или песком пешеходных дорожек, парковок, тротуаров.',
    fourthBlockTitle: 'Также производятся работы по:',
    fourthBlock1List: [
        '1. складированию снега в специально отведенных местах;',
        '2. вывозу снега;',
        '3. уборке мусора;',
        '4. удалению мусора из урн и их промывка;',
        '5. уборке площадки размещения мусорных контейнеров и бункеров;',
        '6. взаимодействию с контролирующими органами.'
    ],
    fifthBlockTitle: 'Применяемая техника при уборке помещений и территорий',
    fifthBlock1Paragraph: 'В зависимости от площади обслуживаемого участка и от вида уборки вне помещений применяется ручной инструмент, средства малой механизации и коммунальная техника.',
    fifthBlock2Paragraph: 'Ручной инструмент. К данной группе относятся метла, лопата, захват, уборочные тележки и т.д. Метлы для уборки вне помещений изготовливаются из полипропилена. Срок службы такого инструмента достигает 12 месяцев при ежедневном использовании. Захват позволяет собирать мусор и антисанитарные предметы, не прикасаясь к ним руками. Лопаты для уборки вне помещений бывают металлические и пропиленовые.',
    fifthBlock3Paragraph: 'Средства малой механизации. К данному виду относятся агрегаты на основе мотоблоков с навесным оборудованием: специальными лопатами для уборки снега и мусора с дорожек, тротуаров, роторными щетками для подметания улицы.',
    fifthBlock4Paragraph: 'Механизированная уборочная техника. Это коммунальная техника на базе тракторного или автомобильного шасси: подметальные машины, предназначенные для сбора опавших листьев, грязи, мусора, а также поливомоечное оборудование, которое используется при сильной запыленности дорог.',
    fifthBlock5Paragraph: 'Для того, чтобы более подробно узнать стоимость уборки помещений и прилегающих территорий, обратитесь к нашим специалистам.',
}

export const servicePage4 = {
    firstBlockTitle: 'Комплексная уборка помещений',
    firstBlock1Paragraph: 'ООО «РСК» предлагает услуги по комплексной уборке, включающие полный спектр мероприятий, которые обеспечивают содержание в чистоте помещения и прилегающие территории. Предоставляя эту услугу, функции по контролю над персоналом, наличием инвентаря и качества конечного результата наша компания берет на себя, что позволяет заказчику снизить общие затраты времени и средств.',
    secondBlockTitle: 'Делая заказ в ООО «РСК», Вы получаете',
    secondBlock1Paragraph: 'Согласованный график работ. Сотрудник нашей компании проводит на месте оценку объекта и вместе с заказчиком согласовывает режим и перечень необходимых комплексных работ.  Затем устанавливается график проведения таких мероприятий по уборке, как глубокая комплексная очистка ковровых покрытий, полировка мебели, чистка жалюзи и др. Возможно обеспечение гигиеническими материалами – мылом, бумажными полотенцами и туалетной бумагой.',
    secondBlock2Paragraph: 'Гарантии качества. После того как график работ по уборке согласован, составляется договор на оказание комплексных услуг. В документе оговаривается площадь помещения, перечень услуг, режим работы, количество расходных и моющих средств. Договор служит гарантией качества выполняемых работ.',
    secondBlock3Paragraph: 'Комплексные услуги. В спектр услуг нашей компании входят:',
    secondBlock1List: [
        '1. влажная уборка полов;',
        '2. ручная уборка в местах с ограниченным доступом;',
        '3. глубокая влажная уборка и очистка ковровых покрытий;',
        '4. полировка деревянной мебели;',
        '5. комплексная очистка рабочих поверхностей;',
        '6. мытье и полировка зеркал и поверхностей из стекла;',
        '7. уборка душевых кабин и санузлов с проведением дезинфекции;',
        '8. комплексная уборка кухонных помещений;',
        '9. мытье окон, жалюзи и потолочных светильников;',
        '10. уборка мусора в помещении, замена пакетов.'
    ],
    thirdBlockTitle: 'Какие средства и технику использует наша компания',
    thirdBlock1Paragraph: 'Комплексная уборка помещений проводится с использованием профессиональной уборочной техники, инструмента и инвентаря: пылесосов, циклонов, грязеводососов, однодисковых машин для очистки полов в помещениях с разными покрытиями, поломоечных, ковромоечных и подметальных машин, тележек, швабр, скребков от ведущих производителей – TASKI, EASTMOP, COLUMBUS, UNGER.  В распоряжении компании высокоэффективные, биоразлагаемые, экологически безопасные профессиональные моющие средства торговых марок DR. SCHNELL CHEMIE, TASKI, KIEHL, ECOLAB, АМС-Медиа.',
    fourthBlockTitle: 'Какие дополнительные услуги по уборке можно заказать',
    fourthBlock1Paragraph: 'Наша компания осуществляет вывоз отходов и образовавшегося мусора после ремонта. Также мы оказываем комплексные услуги по сезонной механизированной и ручной уборке прилегающей территории от листьев и снега, осуществляем вывоз снежных масс на полигоны.',
}

export const servicePage5 = {
    firstBlockTitle: 'Сезонное обслуживание зданий',
    firstBlock1Paragraph: 'Данная услуга включает работы на элементах зданий, обусловленные сезонной необходимостью, при эксплуатации жилых зданий и сооружений. Прежде всего, это:',
    firstBlock2Title: '1. Удаление с кровли снега и наледи.',
    firstBlock3Paragraph: 'При заключении абонентского договора на зимний период нами производится подготовка кровли к зиме: осмотр кровли, устранение мест возможной протечки, очистка водостоков и воронок от листвы и мусора, установка на воронки заглушек, очистка кровли от снега и наледи в период действия договора без ограничений, взаимодействие с контролирующими органами, ограждение места производства работ, соблюдение мер техники безопасности при производстве работ.',
    firstBlock4Paragraph: 'При производстве единоразовой очистки кровли нами удаляется снег и наледь в объеме, согласованном с Заказчиком, с ограждением места проведения работ. При этом Заказчик самостоятельно оповещает сотрудников или третьих лиц о дате и времени производства работ и взаимодействует с контролирующими органами.',
    firstBlock5Paragraph: 'Мы производим уборку снега и наледи с кровель любой формы и сложности, не зависимо от вида кровельных материалов. Наш персонал прошел необходимое обучение, что подтверждено соответствующими удостоверениями.',
    firstBlock3Title: '2. Мытье остекления, фасадов, элементов рекламы.',
    firstBlock7Paragraph: 'Как правило данные работы проводятся дважды в год весной и осенью. Однако есть исключения, когда здания расположены вдоль крупных магистралей, и количество помывок в этих случаях превышает два раза. Данные работы нами проводятся на основании как годовых договоров с указанием графика и условий мытья в течении срока действия договора, так и на основании договоров на разовое мытье. В обоих вариантах нами используется высококвалифицированный персонал и моющие средства с антистатическим эффектом. При производстве работ нами используются естественные опоры, лестницы, туры, автовышки, вертикальные опоры. Пользуясь услугами клининговой компании «РСК», Вы всегда можете быть уверены в отличном результате.',
}

export const servicePage6 = {
    firstBlockTitle: 'Уборка коттеджей и квартир',
    firstBlock1Paragraph: 'Данный вид услуги выделен нами отдельно лишь по причине удобства поиска для Заказчика. Опыт и навыки, приобретенные нами во время сотрудничества как с организациями так и с частными лицами мы с успехом применяем при оказании нами всего перечня клининговых услуг Профессиональной уборки. В коттеджах, домах, квартирах мы проводим все виды уборки помещений, которые включают в себя приведение в порядок полов из известняка на кухнях, люстры из хрусталя и чешского или венецианского стекла расположенных в купольной части дома или над лестницами, шелковые и шерстяные ковры, гобеленовую обивку мебели, стеклянные витражи и крыши в бассейнах, деревянные поверхности в банях, восстановление и защиту каменных полов.',
    firstBlock2Title: 'Цена на услуги договорная',
}

export const servicePage7 = {
    firstBlockTitle: 'Уборка офисов',
    firstBlock1Paragraph: 'ООО «РСК» предлагает коммерческим и бюджетным учреждениям профессиональные услуги по уборке офисных помещений с применением качественных чистящих, моющих средств и высокотехнологичного оборудования от ведущих мировых производителей.',
    secondBlockTitle: 'Наши услуги',
    secondBlock1Paragraph: 'Удаление пыли. Уборка офисных помещений включает удаление пыли с рабочих поверхностей, корпусной мебели, осветительных приборов, компьютерной техники, зеркал, предметов интерьера. При необходимости проводятся работы по чистке радиаторных решеток, батарей, плинтусов, гардин, вентиляционных каналов и дверных блоков помещения. Удаление пыли осуществляется мягкими салфетками, не оставляющими царапин, волокон и разводов. Чистка рабочих поверхностей проводится ежедневно, труднодоступных мест – раз в неделю.',
    secondBlock2Paragraph: 'Влажная уборка. Включает очистку различных видов напольного покрытия (линолеума, паркета, ламината, мрамора, плитки), удаление загрязнений с дверных блоков, плинтусов, рабочих поверхностей. При необходимости проводятся работы по мойке стен и потолков с влагостойким покрытием. Уборка офисов осуществляется с помощью профессиональных чистящих и моющих средств ведущих торговых марок: TASKI, KIEHL, ECOLAB и других. Регулярное проведение работ позволит увлажнить сухой офисный воздух.',
    secondBlock3Paragraph: 'Химчистка. Включает сухую и влажную химическую чистку текстильной обивки диванов и кресел, паласов, ковровых покрытий из синтетических и натуральных материалов. Осуществляется с помощью промышленных моющих пылесосов и безопасных гипоаллергенных средств, которые эффективно и качественно удалят с обрабатываемых поверхностей пыль, грязь, микробы, бактерии. Химическую чистку следует проводить не реже одного раза в год.',
    secondBlock4Paragraph: 'Обработка полиролью. Полиролью обрабатывается различная мебель в офисе (компьютерные и рабочие столы, шкафы, стулья, тумбы, перегородки), двери и дверные проемы. Наша компания использует средства, в состав которых входят антистатические элементы и воск, образующий на очищаемой поверхности защитную пленку. Натирка осуществляется неткаными мягкими салфетками, которые не оставляют царапин и волокон. Обработку полиролью следует проводить один раз в месяц.',
    secondBlock5Paragraph: 'Мытье окон. Наша компания осуществляет мытье пластиковых и деревянных окон в офисах. При этом используются щетки, сгоны и моющие средства с распылителем, которые эффективно очищают поверхность и придают стеклам зеркальный блеск. Мытье окон производится два раза в год: перед летним и зимним периодами. Если помещение для офиса расположено возле оживленной трассы, услуги оказываются по мере необходимости.',
    secondBlock6Paragraph: 'Уборка санитарных помещений. Комплексная уборка санитарных помещений в офисе включает дезинфекцию унитазов, раковин, напольного покрытия (кафеля, плитки). Работы осуществляются в герметичных перчатках с помощью безопасных для здоровья дезодорирующих и дезинфицирующих моющих средств, которые эффективно удаляют известковый налет, загрязнения и вредные микроорганизмы. Чистку и дезинфекцию санитарных помещений в офисе следует проводить ежедневно.',
    secondBlock7Paragraph: 'Вывоз мусора. Наша компания осуществляет вывоз различного мусора, скопившегося при текущей деятельности или возникшего после проведения отделочных, облицовочных, штукатурных и прочих ремонтных работ. Отходы упаковываются в мешки и вывозятся из офисов на уборочной технике, оборудованной контейнерами. Данные работы проводятся при необходимости.',
    thirdBlockTitle: 'Виды уборки офисов',
    thirdBlock1Paragraph: 'Генеральная. Уборка офисных помещений данного вида включает химическую чистку мебельной обивки и ковровых покрытий, обработку мебели полиролью, мытье дверных блоков, окон и внутренних поверхностей шкафов, удаление пыли с батарей отопления, вентиляционных решеток и прочих труднодоступных мест.',
    thirdBlock2Paragraph: 'Ежедневная. Проводимые ежедневно мероприятия по уборке помещений включают мытье напольного покрытия, очистку пепельниц и мусорных урн, дезинфекцию санитарных зон, удаление пыли с рабочих поверхностей, подоконников, предметов интерьера и оргтехники с помощью средств с антистатическим эффектом.',
    thirdBlock3Paragraph: 'Послеремонтная. После офисного ремонта требуется проведение уборки, включающей работы по очистке помещения от остатков строительного мусора, по удалению пыли со стен, потолков, ниш и вентиляционных решеток с помощью промышленного пылесоса, по выведению различных пятен от цемента, известки, затирки, клея, краски, лаков и прочих материалов.',
}

export const servicePage8 = {
    firstBlockTitle: 'Уборка помещений по завершению строительства, ремонта, переезда',
    firstBlock1Paragraph: 'Сфера деятельности ООО «РСК» – оказание услуг по уборке помещений после проведения в них ремонтных или строительных работ, а также при переезде. В своей работе наша компания использует не только накопленный опыт, но и современное оборудование',
    firstBlock2Paragraph: 'Уборка помещений после строительства или ремонта производится с применением чистящих средств, способных удалить следы известковых, цементных, лакокрасочных материалов, а также остатки клея.',
    firstBlock3Paragraph: 'Оказание комплексных услуг по уборке помещений включает очистку вентиляционных решеток, удаление пыли с поверхности стен, потолков, светильников и декоративных элементов. Аналогичная уборка необходима перед началом ремонтных работ, например, перед покраской стен или наклеиванием на них обоев.',
    firstBlock4Paragraph: 'Чтобы получить более подробную информацию о предлагаемых компанией клининговых услугах, обратитесь к нашим специалистам.',
    secondBlockTitle: 'Перечень работ',
    secondBlock1Paragraph: 'В перечень услуг по уборке помещений по завершении строительства, ремонта или переезда входят следующие виды работ',
    secondBlock1List: [
        '•\tУдаление пыли с различных поверхностей. После удаления крупного строительного мусора осуществляется очистка твердых поверхностей с помощью промышленных пылесосов и последующая механическая зачистка.',
        '•\tУборка санитарных помещений. Оказание услуг по санитарно-гигиенической обработке санузлов включает комплексную помывку унитазов, умывальников, кранов с применением химических средств. Заключительным этапом уборки является очистка полов, стен, дверей и перегородок.',
        '•\tМытье светильников. Перед началом работ в помещении производится отключение электропитания. Для очистки труднодоступных элементов осветительных приборов используются лестницы и монтажное оборудование. Специалисты подбирают химические моющие средства и инвентарь в зависимости от материала, из которого изготовлены светильники (хрусталь, металл, керамика, дерево).',
        '•\tЧистка вентиляционных решеток. Перед началом работ по очистке и дезинфекции вентиляционных решеток производится их демонтаж. В зависимости от степени и вида загрязнений (строительная пыль, жир, грязь) специалисты подбирают моющие химические средства и способ очистки (ручная или механическая).',
        '•\tЧистка радиаторов отопления. Данный вид работ заключается в помывке радиаторов отопления синтетическими моющими средствами. Возможно удаление грязи и пыли методом паровой чистки.',
    ],
    thirdBlockTitle: 'Используемые средства',
    thirdBlock1List: [
        '•\tСинтетические моющие средства. Уборка осуществляется с применением профессиональных моющих и чистящих средств российских и мировых производителей. В ходе работ используется продукция марок TASKI (Швейцария), KIEHL, DR.SCHNELL CHEMIE (Германия), ECOLAB (США), АМС-Медиа (Россия).',
        '•\tОборудование. При оказании услуг по уборке помещений наша компания использует профессиональные пылесосы, циклоны, однодисковые машины для мытья различных напольных покрытий, подметательные и ковромоечные аппараты. Уборка производится при помощи оборудования ведущих торговых марок: TASKI (Швейцария), EASTMOP (Великобритания), COLUMBUS и UNGER (Германия).',
    ],
}

export const servicePage9 = {
    firstBlockTitle: 'Уборка производственных помещений',
    firstBlock1Paragraph: 'ООО ГК «ИНТАЛЭКС» предлагает комплекс услуг по уборке помещений внутри зданий, а также по уходу за прилегающей территорией. Наша компания работает в Москве, Московской области, Краснодаре, Санкт-Петербурге, Новосибирске, Нижнем Новгороде, Екатеринбурге.',
    firstBlock2Paragraph: 'Уборка складских, служебных, производственных помещений осуществляется по заранее согласованному графику и в соответствии со стандартом, утвержденным Заказчиком. Комплексная уборка на предприятиях должна проводиться регулярно, так как чистота производственных площадей положительно сказывается на производительности труда персонала. Работа в чистом помещении помогает сохранить здоровье сотрудников и повысить эффективность их деятельности. Помимо этого, состояние рабочих площадей имеет имиджевое значение для компании.',
    secondBlockTitle: 'Виды работ в помещении',
    secondBlock1Paragraph: 'Вынос строительного мусора из здания. Оперативная и своевременная очистка рабочих площадей является важной частью уборки. Как правило, строительный мусор имеет крупные габариты. Этот фактор делает целесообразным для клиента обращение к услугам грузчиков и использование специальной техники на базе тракторного или автомобильного шасси, а также средств малой механизации (агрегатов на основе мотоблоков с навесным оборудованием).',
    secondBlock2Paragraph: 'Мойка окон и подоконников. Загрязненные стекла хуже пропускают свет, что отрицательно сказывается на уровне освещенности рабочего пространства. В процессе мытья окон производственных помещений используются профессиональные чистящие средства таких импортных и отечественных производителей, как KIEHL, TASKI, DR. SCHNELL CHEMIE, АМС-Медиа, ECOLAB.',
    secondBlock3Paragraph: 'Удаление пыли с поверхностей. Данный вид загрязнений может вызывать аллергические реакции, поэтому необходимым является своевременное удаление пыли с поверхностей мебели и техники. Уборка производственных помещений осуществляется при помощи профессионального инвентаря и инструментов таких фирм, как EASTMOP, COLUMBUS, TASKI, UNGER.',
    secondBlock4Paragraph: 'Мойка твердых полов. Уборка производственных площадей осуществляется с помощью профессиональной техники – циклонов, пылесосов, грязеводососов, однодисковых машин для уборки полов с разными покрытиями, поломоечных и подметальных машин, тележек, скребков.',
    secondBlock5Paragraph: 'Чистка и дезинфекция сантехники. Скопления загрязнений, повреждения и засорения разрушают сантехнические конструкции. Уборка санитарных помещений осуществляется с использованием современных биоразлагаемых, экологичных чистящих и дезинфицирующих средств отечественных и импортных производителей.',
    secondBlock6Paragraph: 'Воспользовавшись услугами клининговой компании «РСК», Вы сможете позаботиться о своих сотрудниках, и эффективно развивать бизнес.',
}

export const servicePage10 = {
    firstBlockTitle: 'Чистка, уход и обслуживание отдельных поверхностей',
    firstBlock1Paragraph: 'Часто возникает необходимость произвести уборку помещений или отдельных поверхностей в следствии форс-мажорных обстоятельств (пожар, протечки, затопления и др.), в силу специфики работ (химчистки, обработка и восстановление каменных покрытий и др.).',
    firstBlock2Paragraph: 'Мы обладаем всеми необходимыми технологиями и располагаем высококвалифицированным персоналом.',
    firstBlock3Paragraph: 'Мы готовы всегда прийти на помощь в любой ситуации и произвести:',
    firstBlock1List: [
        '•\tхимическую чистку, пенную чистку, чистку паром ковров и ковровых покрытий из натуральных и синтетических материалов;',
        '•\tхимическую чистку синтетических и натуральных линолеумных покрытий;',
        '•\tхимическую чистку, чистку паром напольных покрытий из керамики, искусственного и натурального камня',
        '•\tхимическую чистку, пенную чистку, чистку паром текстильной обивки мебели;',
        '•\tнанесение полимерного покрытия на натуральный камень, линолеум;',
        '•\tшлифовка, полировка натурального камня;',
        '•\tкристаллизация мрамора;',
        '•\tмытье остекления и фасадов, в том числе с применением автовышки и вертикальных опор.'
    ],
    firstBlock4Paragraph: 'Практика показала, что привлечение в данных случаях профессиональной уборки значительно увеличивает срок эксплуатации жилых зданий и сооружений, а также данных изделий без дополнительного ремонта или восстановления.',
}

export const servicePage11 = {
    firstBlockTitle: 'Техническая эксплуатация зданий и сооружений',
    firstBlock1Paragraph: 'Сегодня мы предлагаем клиентам услуги по технической эксплуатации инженерных сетей и оборудования объектов недвижимости как на отдельных видах, так и в комплексе. При этом для нас не имеет значения уровень сложности оборудования и срок давности введения его в эксплуатацию.',
    firstBlock2Paragraph: 'В зависимости от договорных условий мы оказываем услуги силами:',
    firstBlock1List: [
        '1.\tслужбы эксплуатационного обслуживания на объекте;',
        '2.\tспециализированных мобильных подразделений;',
        '3.\tаварийных бригад.'
    ],
    firstBlock3Paragraph: 'Прием заявок от клиента, контроль и учет выполнения заявок аварийными бригадами, организация взаимодействия между клиентом и подразделениями службы эксплуатации зданий и сооружений в ходе текущей производственной деятельности осуществляется через круглосуточную Диспетчерскую службу, оборудованную автоматизированной системой мониторинга за передвижением специализированных мобильных подразделений и аварийных бригад через систему спутникового слежения.',
    secondBlockTitle: 'В рамках договорных обязательств по технической эксплуатации зданий и сооружений, обслуживанию инженерных сетей и оборудования мы осуществляем:',
    secondBlock1Paragraph: 'Приемку инженерных систем и оборудования:',
    secondBlock1List: [
        '1.\tОбследование инженерных систем и оборудования объекта для выяснения их фактического состояния, работоспособности и параллельное внесение в программный комплекс TRIM – информационную систему управления ТОиР. Результаты обследования актируются.',
        '2.\tПроверку наличия эксплуатационной, проектной и приемно-сдаточной документации.',
        '3.\tПроверку соответствия монтажа инженерных систем и оборудования рабочему проекту.',
        '4.\tРазработку планов и графиков обслуживания объекта на год, квартал, месяц.'
    ],
    secondBlock2Paragraph: 'Эксплуатационное обслуживание инженерных систем и оборудования, направленное на поддержание исправного состояния и заданных параметров режимов работы инженерных систем и оборудования объекта:',
    secondBlock2List: [
        '1.\tЕжедневное техническое обслуживание (ЕТО).',
        '2.\tПланово-предупредительный ремонт и регламентные работы (ППР).',
        '3.\tТекущий ремонт (ТР).'
    ],
    secondBlock3Paragraph: 'Нормативные мероприятия:',
    secondBlock3List: [
        '1.\tВедение оперативной, эксплуатационной документации и паспорта объекта.',
        '2.\tРазработку инструкций по ЕТО, ППР, ТР, ОТ, ППБ.',
        '3.\tВыполнение законодательных нормативных актов, предписаний и инструкций по эксплуатации жилых зданий и сооружений',
        '4.\tАттестацию персонала.',
        '5.\tСоблюдение поверочных интервалов счетчиков, электроинструмента, защитных средств.'
    ],
    secondBlock4Paragraph: 'Технический консалтинг:',
    secondBlock4List: [
        '1.\tВзаимодействие с государственными органами контроля и надзора.',
        '2.\tПредставление интересов собственника перед поставщиками коммунальных услуг.',
        '3.\tКоординацию и обеспечение гарантийных обязательств, заключение договоров субподряда со специализированными организациями и взаимодействие с ними, подготовку тендерной документации.',
        '4.\tКонсультации по вопросам модернизации старых и интегрированию новых инженерных сетей и оборудования.'
    ],
    secondBlock5Paragraph: 'Обеспечение ресурсами:',
    secondBlock5List: [
        '1.\tЦентрализованное бесперебойное материально-техническое снабжение.',
        '2.\tОрганизацию складских резервов.',
        '3.\tУслуги по уборке помещений и прилегающих территорий жилого или общественного здания.'
    ],
}

export const servicePage12 = {
    firstBlockTitle: 'Строительство, ремонт, отделка',
    firstBlock1Paragraph: 'Мы выполняем ремонт объектов, отделочные, ремонтно-строительные работы помещений и зданий, не зависимо от их функционального назначения, разного уровня сложности.',
    firstBlock2Paragraph: 'Мы производим следующие виды работ и услуг:',
    firstBlock1List: [
        '•\tфункции Технического контроля и Генподрядчика по строительству, реконструкции и ремонту;',
        '•\tкосметический ремонт'
    ],
    firstBlock3Paragraph: 'Мы предоставляем гарантии на все выполненные нами ремонтно–строительные работы.',
    firstBlock4Paragraph: 'В своем штате мы располагаем высококвалифицированными специалистами - инженерами, сметчиками, рабочими по строительству, отделке, монтажу инженерных систем.  ',
    firstBlock5Paragraph: 'Нами накоплен опыт в проведении перепланировок и косметического ремонта офисных, торговых, складских и производственных помещений. С учетом пожеланий Заказчика мы готовы выполнить полную или частичную перепланировку помещений, слом старых перегородок и сантехнических кабин, выравнивание стен и монтаж подвесных потолков, настилку полов, замену инженерных систем и оборудования. ',
    firstBlock6Paragraph: 'Также мы готовы выполнить любые работы в области эксплуатации жилых зданий и сооружений.',
}

export const servicePage13 = {
    secondBlockTitle: 'Техническая эксплуатация инженерных сетей и оборудования общественных и жилых зданий и сооружений.',
    secondBlock1Paragraph: 'Сегодня мы предлагаем клиентам услуги по технической эксплуатации инженерных сетей и оборудования объектов недвижимости как на отдельных видах, так и в комплексе. При этом для нас не имеет значения уровень сложности оборудования и срок давности введения его в эксплуатацию.',
    secondBlock2Paragraph: 'В зависимости от договорных условий мы оказываем услуги силами:',
    secondBlock3ParagraphList: ['1. службы эксплуатационного обслуживания на объекте;', '2. специализированных мобильных подразделений;', '3. аварийных бригад;'],
    secondBlock4Paragraph: 'Прием заявок от клиента, контроль и учет выполнения заявок аварийными бригадами, организация взаимодействия между клиентом и подразделениями службы эксплуатации зданий и сооружений в ходе текущей производственной деятельности осуществляется через круглосуточную Диспетчерскую службу, оборудованную автоматизированной системой мониторинга за передвижением специализированных мобильных подразделений и аварийных бригад через систему спутникового слежения.',
    secondBlock1Subtitle: 'В рамках договорных обязательств по технической эксплуатации зданий и сооружений, обслуживанию инженерных сетей и оборудования мы осуществляем:',
    secondBlock1ListSubtitle: '- Приемку инженерных систем и оборудования:',
    secondBlock1List: [
        '1. Обследование инженерных систем и оборудования объекта для выяснения их фактического состояния, работоспособности и параллельное внесение в программный комплекс TRIM – информационную систему управления ТОиР. Результаты обследования актируются.',
        '2. Проверку наличия эксплуатационной, проектной и приемно-сдаточной документации.',
        '3. Проверку соответствия монтажа инженерных систем и оборудования рабочему проекту.',
        '4. Разработку планов и графиков обслуживания объекта на год, квартал, месяц.',
    ],
    secondBlock2ListSubtitle: '- Эксплуатационное обслуживание инженерных систем и оборудования, направленное на поддержание исправного состояния и заданных параметров режимов работы инженерных систем и оборудования объекта:',
    secondBlock2List: [
        '1. Ежедневное техническое обслуживание (ЕТО).',
        '2. Планово-предупредительный ремонт и регламентные работы (ППР).',
        '3. Текущий ремонт (ТР).'
    ],
    secondBlock3ListSubtitle: '- Нормативные мероприятия:',
    secondBlock3List: [
        '1. Ведение оперативной, эксплуатационной документации и паспорта объекта.',
        '2. Разработку инструкций по ЕТО, ППР, ТР, ОТ, ППБ.',
        '3. Выполнение законодательных нормативных актов, предписаний и инструкций по эксплуатации жилых зданий и сооружений.',
        '4. Аттестацию персонала.',
        '5. Соблюдение поверочных интервалов счетчиков, электроинструмента, защитных средств.',
    ],
    secondBlock4ListSubtitle: '- Технический консалтинг:',
    secondBlock4List: [
        '1. Взаимодействие с государственными органами контроля и надзора.',
        '2. Представление интересов собственника перед поставщиками коммунальных услуг.',
        '3. Координацию и обеспечение гарантийных обязательств, заключение договоров субподряда со специализированными организациями и взаимодействие с ними, подготовку тендерной документации.',
        '4. Консультации по вопросам модернизации старых и интегрированию новых инженерных сетей и оборудования.',
    ],
    secondBlock5ListSubtitle: '- Обеспечение ресурсами:',
    secondBlock5List: [
        '1. Централизованное бесперебойное материально-техническое снабжение.',
        '2. Организацию складских резервов.',
        '3. Услуги по уборке помещений и прилегающих территорий жилого или общественного здания.',
    ],
}

export const aboutUs = {
    firstBlockTitle: 'О нас',
    firstBlock1Paragraph: 'Наша история началась в 2015 году, когда группа профессионалов, с большим опытом в клининговом бизнесе в Москве и Московской области, объединилась. За время своего существования мы разработали эффективный подход к решению задач и расширили свою деятельность на различные регионы России. Сегодня наше предприятие уверенно занимает лидирующие позиции на рынке технической эксплуатации недвижимости в различных регионах страны. Наша команда профессионалов, ориентированная на качество обслуживания и понимание потребностей клиентов, является ключом к успешному развитию компании и долгосрочному сотрудничеству. Мы стремимся к созданию эффективной системы обслуживания недвижимости, чтобы обеспечить максимальный комфорт нашим клиентам.',
}

export const purchasesPage = {
    firstBlockTitle: 'Информация для поставщиков',
    firstBlock1Paragraph: 'Уважаемые Поставщики! ООО «РСК» приглашает Вас к сотрудничеству по следующим направлениям оказания услуг и работ:',
    firstBlock1List: [
        '• Техническая эксплуатация инженерных систем и содержание объектов недвижимости',
        '• Ремонтно-строительные работы самого различного объема и назначения',
        '• Монтаж, пуско-наладка и сервисное обслуживание инженерных систем зданий',
        '• Комплексная уборка помещений объекта, прилегающей территории, озеленение и уход за зелеными насаждениями, отдельные услуги: разовые, послестроительные уборки, мытье окон, фасадов, кристаллизация и шлифовка мрамора и др.',
        '• Технический надзор и техническое сопровождение хода строительства',
        '• Аварийно- диспетчерское обслуживание объектов',
        '• Электрические испытания и электроизмерения на электроустановках',
        '• Организация корпоративного питания сотрудников на объектах',
        '• Аутстаффинг и аутсорсинг',
        '• Энергоаудит (энергетическое обследование) объектов недвижимости'
    ],
    firstBlock2Paragraph: `В случае вашей заинтересованности в прохождении аккредитации поставщика просим Вас направлять необходимый комплект документов на эл.почту `,
    firstBlock3Paragraph: 'Приложение: Необходимый комплект документов для прохождения аккредитации поставщика:',
    download1: 'Письмо участника',
    download2: 'Анкета поставщика',
    download3: 'Комплект документов соискателя',
}

export const contacts = {
    address: 'Адрес: г. Москва, ул. Черняховского, д. 16',
    phone: 'Телефон: +7(495) 136-21-85',
    fax: 'Тел./факс: +7(495) 136-21-85',
    email: `E-mail: ${email}`,
}



import React from 'react';
import styles from './styles.module.css'
import {purchasesPage, email} from "../content/Text";


const Purchases = () => {
    const mailTo = `mailto:${email}`

    return (
        <div className={styles.wrapper}>
            <div className={styles.block}>
                <h1 className={styles.mainTitle}>{purchasesPage.firstBlockTitle}</h1>
                <p className={styles.paragraph}>{purchasesPage.firstBlock1Paragraph}</p>
                <ul className={styles.list}>{purchasesPage.firstBlock1List.map(((listItem, index) => <li
                    key={listItem}>{listItem}</li>))}</ul>
                <p className={styles.paragraph}>{purchasesPage.firstBlock2Paragraph}<a href={mailTo} className={styles.link}>{email}</a></p>
                <p className={styles.paragraph} style={{width: '100%', justifySelf: 'start'}}>{purchasesPage.firstBlock3Paragraph}</p>
                <a href={require("../content/Pismo_Uchastnika.docx")} download className={styles.downloadLink}>{purchasesPage.download1}</a>
                <a href={require("../content/Anketa_Soiskatelya.docx")} download className={styles.downloadLink}>{purchasesPage.download2}</a>
                <a href={require("../content/Komplekt_Dokumentov.docx")} download className={styles.downloadLink}>{purchasesPage.download3}</a>
            </div>
        </div>
    );
};

export default Purchases;
import React, {useEffect} from 'react';
import styles from "./styles.module.css";
import {servicePage4} from "../content/Text";

const Sp4 = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className={styles.wrapper}>
            <div className={styles.block}>
                <h1 className={styles.mainTitle}>{servicePage4.firstBlockTitle}</h1>
                <p className={styles.paragraph}>{servicePage4.firstBlock1Paragraph}</p>
            </div>
            <div className={styles.block}>
                <h2 className={styles.mainTitle}>{servicePage4.secondBlockTitle}</h2>
                <p className={styles.paragraph}>{servicePage4.secondBlock1Paragraph}</p>
                <p className={styles.paragraph}>{servicePage4.secondBlock2Paragraph}</p>
                <p className={styles.paragraph} style={{width: '100%', justifySelf: 'start'}}>{servicePage4.secondBlock3Paragraph}</p>
                <ul className={styles.list}>{servicePage4.secondBlock1List.map(((listItem, index) => <li
                    key={listItem}>{listItem}</li>))}</ul>
            </div>
            <div className={styles.block}>
                <h2 className={styles.mainTitle}>{servicePage4.thirdBlockTitle}</h2>
                <p className={styles.paragraph}>{servicePage4.thirdBlock1Paragraph}</p>
            </div>
            <div className={styles.block}>
                <h2 className={styles.mainTitle}>{servicePage4.fourthBlockTitle}</h2>
                <p className={styles.paragraph}>{servicePage4.fourthBlock1Paragraph}</p>
            </div>

        </div>
    );
};

export default Sp4;
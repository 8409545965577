import React from 'react';
import styles from './styles.module.css'
import {contacts, mainPageText} from "../../content/Text";

const Footer = () => {
    return (
        <div className={styles.footerWrapper}>
            <div className={styles.footerContainer}>
                <span className={styles.footerTitle}>{mainPageText.firstBlockTitle}</span>
                <div className={styles.cellPhoneBlock}>
                    <span className={styles.footerTelephone}>{contacts.address}</span>
                    <a href="tel:+74951362185" className={styles.footerTelephone}>{contacts.phone}</a>
                    <a href="mailto:office@rsc.moscow" className={styles.footerTelephone}>{contacts.email}</a>
                </div>
            </div>
        </div>
    );
};

export default Footer;
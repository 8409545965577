export const servicePageDropdownTabs = [
    'Генеральная уборка помещений',
    'Ежедневная уборка внутренних и прилегающих территорий',
    'Ежедневная уборка внутренних помещений',
    'Комплексная уборка помещений',
    'Сезонное обслуживание зданий',
    'Уборка коттеджей, домов, квартир',
    'Уборка офисов',
    'Уборка помещений по завершению строительства, ремонта, переезда',
    'Уборка производственных помещений',
    'Чистка, уход и обслуживание отдельных поверхностей',
    'Техническая эксплуатация зданий и сооружений',
    'Строительство, ремонт, отделка'
]
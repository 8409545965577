import React, {useEffect} from 'react';
import styles from "./styles.module.css";
import {servicePage8} from "../content/Text";

const Sp8 = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className={styles.wrapper}>
            <div className={styles.block}>
                <h1 className={styles.mainTitle}>{servicePage8.firstBlockTitle}</h1>
                <p className={styles.paragraph}>{servicePage8.firstBlock1Paragraph}</p>
                <p className={styles.paragraph}>{servicePage8.firstBlock2Paragraph}</p>
                <p className={styles.paragraph}>{servicePage8.firstBlock3Paragraph}</p>
                <p className={styles.paragraph}>{servicePage8.firstBlock4Paragraph}</p>
            </div>
            <div className={styles.block}>
                <h2 className={styles.mainTitle}>{servicePage8.secondBlockTitle}</h2>
                <p className={styles.paragraph}>{servicePage8.secondBlock1Paragraph}</p>
                <ul className={styles.list}>{servicePage8.secondBlock1List.map(((listItem, index) => <li
                    key={listItem}>{listItem}</li>))}</ul>
            </div>
            <div className={styles.block}>
                <h2 className={styles.mainTitle}>{servicePage8.thirdBlockTitle}</h2>
                <ul className={styles.list}>{servicePage8.thirdBlock1List.map(((listItem, index) => <li
                    key={listItem}>{listItem}</li>))}</ul>
            </div>
        </div>
    );
};

export default Sp8;
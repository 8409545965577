import React from 'react';
import Main from './components/Main';
import { YMaps } from "react-yandex-maps";

function App() {

  return (
      <YMaps>
        <Main/>
      </YMaps>
  )
}

export default App

import React from 'react';
import styles from './styles.module.css'

type Props = {
    content: string,
    onClick: () => void,
    isDriverTab: boolean,
}

const AsideBarTab = ({content, onClick, isDriverTab = false}: Props) => {
    return (
        <div className={isDriverTab ? styles.asideDriverElement : styles.dropdownElement} onClick={onClick}>
            {content}
        </div>
    );
};

export default AsideBarTab;
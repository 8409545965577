import React, {useEffect} from 'react';
import styles from "./styles.module.css";
import {servicePage12} from "../content/Text";

const Sp12 = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className={styles.wrapper}>
            <div className={styles.block}>
                <h1 className={styles.mainTitle}>{servicePage12.firstBlockTitle}</h1>
                <p className={styles.paragraph}>{servicePage12.firstBlock1Paragraph}</p>
                <p className={styles.paragraph}>{servicePage12.firstBlock2Paragraph}</p>
                <ul className={styles.list}>{servicePage12.firstBlock1List.map(((listItem, index) => <li
                    key={listItem}>{listItem}</li>))}</ul>
                <p className={styles.paragraph}>{servicePage12.firstBlock3Paragraph}</p>
                <p className={styles.paragraph}>{servicePage12.firstBlock4Paragraph}</p>
                <p className={styles.paragraph}>{servicePage12.firstBlock5Paragraph}</p>
                <p className={styles.paragraph}>{servicePage12.firstBlock6Paragraph}</p>
            </div>
        </div>
    );
};

export default Sp12;
import React, {useEffect} from 'react';
import styles from "./styles.module.css";
import {servicePage5} from "../content/Text";

const Sp5 = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className={styles.wrapper}>
            <div className={styles.block}>
                <h1 className={styles.mainTitle}>{servicePage5.firstBlockTitle}</h1>
                <p className={styles.paragraph}>{servicePage5.firstBlock1Paragraph}</p>
                <h4 className={styles.paragraph} style={{width: '100%', justifySelf: 'start'}}>{servicePage5.firstBlock2Title}</h4>
                <p className={styles.paragraph}>{servicePage5.firstBlock3Paragraph}</p>
                <p className={styles.paragraph}>{servicePage5.firstBlock4Paragraph}</p>
                <p className={styles.paragraph}>{servicePage5.firstBlock5Paragraph}</p>
                <h4 className={styles.paragraph} style={{width: '100%', justifySelf: 'start'}}>{servicePage5.firstBlock3Title}</h4>
                <p className={styles.paragraph}>{servicePage5.firstBlock7Paragraph}</p>
            </div>
        </div>
    );
};

export default Sp5;
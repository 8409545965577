import styles from './styles.module.css'
import { headerTabs } from "../../content/headerTabs";
import React from 'react';
import Logo from "./Logo";
import ArrowDown from "../ui/ArrowDown";
import ArrowUp from "../ui/ArrowUp";

type TabProps = {
    content: string,
    index: number,
    isDropdownOpen: boolean,
    handleTabClick:  (index: number) => void,
    setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

type HeaderProps = {
    isDropdownOpen: boolean,
    setActiveTab:  React.Dispatch<React.SetStateAction<number>>,
    setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>,
}
const HeaderTab = ({content, index, isDropdownOpen, handleTabClick, setDropdownOpen}: TabProps) => {
    const handleClick = () => {
        handleTabClick(index);
        index === 1 && setDropdownOpen(prev => !prev)
    }

    const renderArrow = (isDropdownOpen: boolean) => {
        return isDropdownOpen ? <ArrowUp/> : <ArrowDown/>
    }

    return (
        <div className={styles.tabElement} onClick={handleClick} >
            {content}
            {index === 1 && renderArrow(isDropdownOpen)}
        </div>
    );
};

const Header = ({ isDropdownOpen, setActiveTab, setDropdownOpen }: HeaderProps) => {

    const handleTabClick = (index: number) => {
        setActiveTab(index)
    }

    return (
        <div className={styles.headerWrapper}>
            <div className={styles.headerLogo}>
             <Logo/>
            </div>
            <div className={styles.headerContainer}>
                <div className={styles.tabList}>
                    {headerTabs.map((headerTab, index) => (
                        <HeaderTab key={headerTab} content={headerTab} index={index} isDropdownOpen={isDropdownOpen} handleTabClick={handleTabClick} setDropdownOpen={setDropdownOpen}/>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Header;
import React, {useEffect} from 'react';
import styles from "./styles.module.css";
import {servicePage1} from "../content/Text";

const Sp1 = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className={styles.wrapper}>
            <h1 className={styles.mainTitle}>{servicePage1.firstBlockTitle}</h1>
            <div className={styles.block}>
                <p className={styles.paragraph}>{servicePage1.firstBlock1Paragraph}</p>
                <p className={styles.paragraph}>{servicePage1.firstBlock2Paragraph}</p>
                <p className={styles.paragraph}>{servicePage1.firstBlock3Paragraph}</p>
                <p className={styles.paragraph}>{servicePage1.firstBlock4Paragraph}</p>
                <p className={styles.paragraph}>{servicePage1.firstBlock5Paragraph}</p>
                <p className={styles.paragraph}>{servicePage1.firstBlock6Paragraph}</p>
                <p className={styles.paragraph}>{servicePage1.firstBlock7Paragraph}</p>
                <p className={styles.paragraph}>{servicePage1.firstBlock8Paragraph}</p>
            </div>
        </div>
    );
};

export default Sp1;
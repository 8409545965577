import React from 'react';
import styles from './styles.module.css'
import {mainPageText} from "../content/Text";
import {Map, Placemark} from "react-yandex-maps";

const MainPage = () => {



    return (
        <div className={styles.wrapper}>
            <h1 className={styles.mainTitle}>{mainPageText.firstBlockTitle}</h1>
            <div className={styles.block}>
                <p className={styles.paragraph}>{mainPageText.firstBlock1Paragraph}</p>
                <p className={styles.paragraph}>{mainPageText.firstBlock2Paragraph}</p>
            </div>
            <div>
              <Map defaultState={{ center: [55.807468107885974, 37.540991926441606], zoom: 17 }} width={1000} height={300}>
                  <Placemark defaultGeometry={[55.807468107885974, 37.540991926441606]} />
              </Map>
            </div>
            <div className={styles.block}>
            <h2>{mainPageText.secondBlockTitle}</h2>
                <ul className={styles.list}>{mainPageText.secondBlockParagraphList.map(((listItem, index) => <li key={listItem}>{listItem}</li>))}</ul>
                {/*ссылка*/}
            </div>
            <div className={styles.block}>
                <h2>{mainPageText.thirdBlockTitle}</h2>
                <p className={styles.paragraph}>{mainPageText.thirdBlock1Paragraph}</p>
                <p className={styles.paragraph}>{mainPageText.thirdBlock2Paragraph}</p>
            </div>
        </div>
    );
};

export default MainPage;
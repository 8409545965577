import React, {SetStateAction, useState} from 'react';
import styles from './styles.module.css'
import ServicePageDropdown from "../components/ServicePageAsideBar/ServicePageDropdown";
import Sp1 from "./Sp1";
import Sp2 from "./Sp2";
import Sp3 from "./Sp3";
import Sp4 from "./Sp4";
import Sp5 from "./Sp5";
import Sp6 from "./Sp6";
import Sp7 from "./Sp7";
import Sp8 from "./Sp8";
import Sp9 from "./Sp9";
import Sp10 from "./Sp10";
import Sp11 from "./Sp11";
import Sp12 from "./Sp12";
import Fade from "../components/ui/Fade";

type ServicePageProps = {
    isDropdownOpen: boolean,
    setDropdownOpen: React.Dispatch<SetStateAction<boolean>>
}

const ServicePage = ({isDropdownOpen, setDropdownOpen}: ServicePageProps) => {
    const [activeTab, setActiveTab] = useState<number>(0);

    React.useEffect(() => {
        setDropdownOpen(true)
        return () => {
            setDropdownOpen(false)
        }
    }, [])

    const handleFadeClick = () => {
        setDropdownOpen(false);
    }

    const renderPage = () => {
        switch (activeTab) {
            case 0: return <Sp1/>
            case 1: return <Sp2/>
            case 2: return <Sp3/>
            case 3: return <Sp4/>
            case 4: return <Sp5/>
            case 5: return <Sp6/>
            case 6: return <Sp7/>
            case 7: return <Sp8/>
            case 8: return <Sp9/>
            case 9: return <Sp10/>
            case 10: return <Sp11/>
            case 11: return <Sp12/>
        }
    }

    return (
        <div className={styles.wrapper}>
            {isDropdownOpen && <Fade onClick={handleFadeClick}/>}
            {isDropdownOpen && <ServicePageDropdown setActiveTab={setActiveTab} setDropdownOpen={setDropdownOpen}/>}
            {renderPage()}
        </div>
    );
};

export default ServicePage;